import resumeItems from './data/resume_items.json';
import skills from './data/skills.json';
import portfolioItems from './data/portfolio_items.json';

class DataAPI {
    static getResumeItems() {
        return resumeItems;
    }

    static getSkills() {
        return skills;
    }

    static getPortfolioItems() {
        return portfolioItems;
    }

    static getPortfolioItemByID(id) {
        return portfolioItems.find(portfolioItem => portfolioItem.id === id);
    }
}

export default DataAPI;
