import React from "react";
import PropTypes from 'prop-types';

import styles from './skill-list-item-styles.module.css';

const SkillListItem = ({skill}) => {
    return (
        <div className={styles.root + ' ' + (skill.isActive === false ? styles.inactiveSkill : '')}>
            <span>{skill.name}</span>

            <div className={styles.skillPercentage}>
                <span style={{width: skill.percentage + '%'}}></span>
            </div>
        </div>
    );
};

SkillListItem.propTypes = {
    skill: PropTypes.object.isRequired
};


export default SkillListItem;
