import React from "react";
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";

import styles from './portfolio-item-styles.module.css';

import PortfolioTags from '../model/PortfolioTags';

const PortfolioItem = ({portfolioItem, itemFilter}) => {
    const thumbnailLocation = portfolioItem.thumbnail;
    let thumbnailSrc = null;

    if (thumbnailLocation !== undefined && thumbnailLocation != null) {
        if (thumbnailLocation.startsWith('http') === true) {
            thumbnailSrc = thumbnailLocation;
        } else {
            thumbnailSrc = `/static/images/portfolio/general/${thumbnailLocation}`;
        }
    } else {
        const i = Math.floor(Math.random() * 4) + 1;

        thumbnailSrc = `/static/images/portfolio/general/thumb${i}.png`;
    }

    return (
        <div>
            {(itemFilter === PortfolioTags.ALL || portfolioItem.tags.includes(itemFilter) === true)  &&
                <div className={'col-xs-12 col-sm-6' + ' ' + styles.portfolioItem}>
                    <div className={styles.projectContent}>
                        <div className={styles.projectImage}>
                            <img src={thumbnailSrc} />
                        </div>

                        <div className={styles.info}>
                            <div className={styles.displayTable}>
                                <div className={styles.displayTableCell}>
                                    {portfolioItem.isExternal !== undefined && portfolioItem.isExternal != null && portfolioItem.isExternal === true ?
                                        <a href={portfolioItem.url} target="_blank">
                                            <h3>{portfolioItem.name + ' '} <i className={'fa fa-external-link'}></i></h3>
                                        </a>
                                        :
                                        <div>
                                            <h3>{portfolioItem.name}</h3>
                                            <Link to={`/portfolio/${encodeURIComponent(portfolioItem.id)}`}>
                                                <i className={'fa fa-link' + ' ' + styles.faLink}></i>
                                            </Link>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    );
};

PortfolioItem.propTypes = {
    portfolioItem: PropTypes.object.isRequired,
    itemFilter: PropTypes.string.isRequired
};


export default PortfolioItem;
