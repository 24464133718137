import React from "react";

import styles from './resume-styles.module.css';

import ResumeItem from '../components/ResumeItem';
import SkillList from '../components/SkillList';

import DataAPI from '../api/DataAPI';

class Resume extends React.Component {
    render() {
        const resumeItems = DataAPI.getResumeItems();
        const skills = DataAPI.getSkills();

        return (
            <div className={'content'}>
                <div className={'container'}>

                    <div className={'section-title'}>
                        <h2>Resume</h2>
                    </div>

                    <div className={'row'}>
                        <div className={'col-md-12'}>
                            <p className={styles.resumeButton}>
                                <a href={'/static/Resume_Timur_Asanov.pdf'} className={'big-button'} target="_blank">Download Resume</a>
                            </p>
                        </div>
                    </div>

                    <div className={'row'}>
                        <div className={'col-md-12'}>

                            {
                                resumeItems.sort((a, b) => b.order - a.order).map((resumeItem) => (
                                    <ResumeItem resumeItem={resumeItem} key={resumeItem.order} />
                                ))
                            }

                        </div>
                    </div>

                    <div className={'row margin-top-30'}>
                        <div className={'col-md-12'}>
                            <p className={styles.resumeButton}>
                                <a href={'/static/Resume_Timur_Asanov.pdf'} className={'big-button'} target="_blank">Download Resume</a>
                            </p>
                        </div>
                    </div>

                    <div className={'row margin-top-30'}>
                        <div className={'col-md-12'}>

                            <SkillList sectionName={'Languages'} skills={skills} />
                            <SkillList sectionName={'Frameworks'} skills={skills} />
                            <SkillList sectionName={'Servers'} skills={skills} />
                            <SkillList sectionName={'Tools'} skills={skills} />
                            <SkillList sectionName={'Other'} skills={skills} />

                        </div>
                    </div>

                </div>
            </div>
        );
    }
}

export default Resume;
