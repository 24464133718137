import React from "react";
import PropTypes from 'prop-types';

import SkillListItem from './SkillListItem';

const SkillListSection = ({skills}) => {
    return (
        <div className={'col-xs-12 col-sm-6'}>
            <div className={'col-xs-12 col-sm-12'}>
                {skills.map(skill => (
                    <SkillListItem key={skill.name} skill={skill} />
                ))}
            </div>
        </div>
    );
};

SkillListSection.propTypes = {
    skills: PropTypes.array.isRequired
};


export default SkillListSection;
