import React from "react";

import styles from './portfolio-item-page-styles.module.css';

import PortfolioImages from './PortfolioImages';

import DataAPI from '../api/DataAPI';

class PortfolioItemPage extends React.Component {
    constructor(props) {
        super(props);

        const { match } = this.props;

        this.state = {
            portfolioItemID: match.params.id,
            portfolioItem: {},
            tagsString: ""
        };
    }

    componentDidMount() {
        const portfolioItem = DataAPI.getPortfolioItemByID(this.state.portfolioItemID);

        if (portfolioItem !== undefined && portfolioItem != null) {
            const tags = portfolioItem.tags;

            let tagsString = "";

            if (tags !== undefined && tags != null && tags.length > 0) {
                tags.sort((a, b) => a > b).map((tag, i) => {
                    tagsString += "#" + tag;

                    if (i !== tags.length - 1) {
                        tagsString += ", ";
                    }
                });
            }

            this.setState({
                portfolioItem: portfolioItem,
                tagsString: tagsString
            });
        } else {
            this.props.history.push('/portfolio');
        }
    }

    render() {
        return (
            <div className={'content'}>
                <div className={'container'}>

                    <div className={'section-title'}>
                        <h2>{this.state.portfolioItem.fullName}</h2>
                    </div>

                    <div className={'row'}>
                        <div className={'col-md-12'}>

                            {this.state.portfolioItem.images !== undefined && this.state.portfolioItem.images != null && this.state.portfolioItem.images.length > 0 &&
                                <PortfolioImages imagesArray={this.state.portfolioItem.images} />
                            }

                            <ul className={styles.infoList + ' ' + 'margin-top-30'}>
                                {this.state.portfolioItem.client !== undefined && this.state.portfolioItem.client != null &&
                                    <li><span>Client</span> : {this.state.portfolioItem.client}</li>
                                }
                                <li><span>Tags</span> : {this.state.tagsString}</li>
                            </ul>

                            <div dangerouslySetInnerHTML={{__html: this.state.portfolioItem.description}} />

                            {this.state.portfolioItem.url !== undefined && this.state.portfolioItem.url != null &&
                                <a href={this.state.portfolioItem.url} className={'big-button'} target="_blank">Visit website</a>
                            }

                        </div>
                    </div>

                </div>
            </div>
        );
    }
}

export default PortfolioItemPage;
