import React from "react";
import PropTypes from 'prop-types';
import ImageGallery from 'react-image-gallery';

const PortfolioImages = ({imagesArray}) => {
    const images = [];

    imagesArray.map(image => {
        images.push({ original: image });
    });

    return (
        <ImageGallery items={images} showPlayButton={false} showThumbnails={false} showFullscreenButton={false} />
    );
};

PortfolioImages.propTypes = {
    imagesArray: PropTypes.array.isRequired
};


export default PortfolioImages;
