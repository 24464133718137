import React from "react";

import styles from './portfolio-styles.module.css';

import PortfolioItem from '../components/PortfolioItem';
import PortfolioTags from '../model/PortfolioTags';

import DataAPI from '../api/DataAPI';

class Portfolio extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            itemFilter: PortfolioTags.ALL
        };

        this.handleFilterClick = this.handleFilterClick.bind(this);
        this.getActiveFilterStyle = this.getActiveFilterStyle.bind(this);
    }

    handleFilterClick(clickedFilter) {
        this.setState({
            itemFilter: clickedFilter
        });
    }

    getActiveFilterStyle(filter) {
        const activeFilterStyle = {
            color: '#000',
            borderBottom: '2px solid #47a3da'
        };

        return this.state.itemFilter === filter ? activeFilterStyle : {};
    }

    render() {
        const portfolioItems = DataAPI.getPortfolioItems();

        return (
            <div className={'content'}>
                <div className={'container'}>

                    <div className={'section-title'}>
                        <h2>Portfolio</h2>
                    </div>

                    <ul className={styles.ulFilter}>
                        <li style={this.getActiveFilterStyle(PortfolioTags.ALL)} onClick={() => this.handleFilterClick(PortfolioTags.ALL)}>all</li>
                        <li style={this.getActiveFilterStyle(PortfolioTags.JAVA)} onClick={() => this.handleFilterClick(PortfolioTags.JAVA)}>java</li>
                        <li style={this.getActiveFilterStyle(PortfolioTags.JS)} onClick={() => this.handleFilterClick(PortfolioTags.JS)}>javascript</li>
                        <li style={this.getActiveFilterStyle(PortfolioTags.REACT)} onClick={() => this.handleFilterClick(PortfolioTags.REACT)}>react</li>
                        <li style={this.getActiveFilterStyle(PortfolioTags.ANGULAR)} onClick={() => this.handleFilterClick(PortfolioTags.ANGULAR)}>angular</li>
                        <li style={this.getActiveFilterStyle(PortfolioTags.SPRING)} onClick={() => this.handleFilterClick(PortfolioTags.SPRING)}>spring</li>
                    </ul>

                    <div className={styles.portfolioRow}>

                        {
                            portfolioItems.sort((a, b) => b.order - a.order).map((portfolioItem) => (
                                <PortfolioItem key={portfolioItem.order} portfolioItem={portfolioItem} itemFilter={this.state.itemFilter} />
                            ))
                        }

                    </div>

                </div>
            </div>
        );
    }
}

export default Portfolio;
