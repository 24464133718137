import React from "react";
import PropTypes from 'prop-types';

import styles from './resume-item-styles.module.css';

const ResumeItem = ({resumeItem}) => {
    return (
        <div className={styles.resumeItem} key={resumeItem.order}>
            <div className={styles.resumeItemDate}>
                <span>{resumeItem.startDate + ' - ' + resumeItem.endDate}</span>
            </div>

            <div>
                <h4>{resumeItem.company}</h4>

                {resumeItem.points !== undefined && resumeItem.points != null && resumeItem.points.length > 0 &&
                    <ul className={styles.resumeItemList}>
                        {resumeItem.points.sort((a, b) => b.order - a.order).map((resumeItemPoint) => (
                            <li key={resumeItemPoint.order}>{resumeItemPoint.description}</li>
                        ))}
                    </ul>
                }
            </div>
        </div>
    );
};

ResumeItem.propTypes = {
    resumeItem: PropTypes.object.isRequired
};


export default ResumeItem;
