import React from "react";
import ReactDOM from "react-dom";

import Main from "./Main";

import './css/bootstrap.css';
import 'font-awesome/css/font-awesome.min.css';
import './css/quicksand_font.css';
import './css/styles.css';
import 'react-image-gallery/styles/css/image-gallery.css';

ReactDOM.render(<Main/>, document.getElementById("app"));
