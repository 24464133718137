import React from "react";

import styles from './home-styles.module.css';

class Home extends React.Component {
    render() {
        return (
            <div className={'content'}>
                <img className={styles.centerBlock} src={'/static/images/about_me.jpg'} />

                <div className={'container'}>
                    <div className={styles.aboutMe}>
                        <p className={'margin-top-30'}>I'm a developer living in Rockville, Maryland.</p>

                        <p>I spend most of my time in back end development in JAVA, but also design and develop the front end of web applications using HTML, CSS, and JavaScript with jQuery, Angular and React. I also enjoy learning new languages and solving problems that are not related to code. </p>

                        <p>I'm very passionate about development and like all parts of development including back end and front end of web applications, server maintenance, architecture and data modeling. Aside from development, I enjoy spending my time travelling, reading books, and watching sports (football, soccer and hockey).</p>

                        <p className={styles.resumeButton}>
                            <a href={'/static/Resume_Timur_Asanov.pdf'} className={'big-button'} target="_blank">Download Resume</a>
                        </p>

                        <p className={styles.quote}>
                            <i className={'fa fa-quote-left'}></i>&nbsp;
                            You can’t have great software without a great team, and most software teams behave like dysfunctional families.
                        </p>

                        <p className={styles.quote}>
                            <i className={'fa fa-quote-left'}></i>&nbsp;
                            Computer science education cannot make anybody an expert programmer any more than studying brushes and pigment can make somebody an expert painter.
                        </p>
                    </div>
                </div>
            </div>
        );
    }
}

export default Home;
