import React from "react";
import PropTypes from 'prop-types';
import { NavLink } from "react-router-dom";

import styles from './sidebar-styles.module.css';

const Sidebar = ({navigationHandler, isMiniMenuCollapsed}) => {
    const activeMenuItemStyle = {
        color: '#47A3DA'
    };

    const rightZeroClass = isMiniMenuCollapsed === true ? '' : styles.rightZero;

    return (
        <aside className={styles.sideRight + ' ' + rightZeroClass}>
            <div className={styles.sideContent}>

                <div className={styles.profileImg}>
                    <img src={'/static/images/profile.jpg'} />
                </div>

                <h1>
                    <span>Timur Asanov</span>
                </h1>

                <nav>
                    <div className={styles.socialIcons}>
                        <a href="https://www.linkedin.com/in/timur-asanov-3b254763" target="_blank" alt="Linked In">
                            <i className={'fa fa-linkedin'}></i>
                        </a>
                        <a href="https://stackoverflow.com/users/7938769" target="_blank" alt="Stack Overflow">
                            <i className={'fa fa-stack-overflow'}></i>
                        </a>
                        <a href="https://gitlab.com/timur-asanov" target="_blank" alt="Gitlab">
                            <i className={'fa fa-gitlab'}></i>
                        </a>
                        <a href="mailto:timur.asanov@tuta.io" target="_blank" alt="Email">
                            <i className={'fa fa-envelope'}></i>
                        </a>
                    </div>

                    <ul className={styles.menuList}>
                        <li>
                            <NavLink exact to="/" activeStyle={activeMenuItemStyle} onClick={navigationHandler()}>about me</NavLink>
                        </li>
                        <li>
                            <NavLink to="/resume" activeStyle={activeMenuItemStyle} onClick={navigationHandler()}>resume</NavLink>
                        </li>
                        <li>
                            <NavLink to="/portfolio" activeStyle={activeMenuItemStyle} onClick={navigationHandler()}>portfolio</NavLink>
                        </li>
                    </ul>
                </nav>

            </div>
        </aside>
    );
};

Sidebar.propTypes = {
    navigationHandler: PropTypes.func.isRequired,
    isMiniMenuCollapsed: PropTypes.bool.isRequired
};

export default Sidebar;
