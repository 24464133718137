import React from "react";
import PropTypes from 'prop-types';

import styles from './mini-menu-styles.module.css';

const MiniMenu = ({miniMenuToggleHandler, isMiniMenuCollapsed}) => {
    const collapsedClass = isMiniMenuCollapsed === true ? styles.collapsed : '';
    const displayNoneClass = isMiniMenuCollapsed === true ? styles.displayNone : '';

    return (
        <div>
            <div className={styles.miniMenuOverlay + ' ' + displayNoneClass}></div>
            <div className={styles.miniMenu}>
                <div className={styles.miniMenuContent}>
                    <p className={styles.menuTitle}>Timur Asanov</p>
                    <button type="button" onClick={miniMenuToggleHandler()} className={styles.navbarToggle + ' ' + collapsedClass}>
                        <span className={styles.iconBar}></span>
                        <span className={styles.iconBar}></span>
                        <span className={styles.iconBar}></span>
                    </button>
                </div>
            </div>
        </div>
    );
};

MiniMenu.propTypes = {
    miniMenuToggleHandler: PropTypes.func.isRequired,
    isMiniMenuCollapsed: PropTypes.bool.isRequired
};


export default MiniMenu;
