import React from "react";
import PropTypes from 'prop-types';

import styles from './skill-list-styles.module.css';

import SkillListSection from './SkillListSection';

const SkillList = ({skills, sectionName}) => {
    const sectionSkills = skills.filter(skill => skill.section === sectionName).sort((a, b) => a.name > b.name);

    const arrayHalfPoint = Math.ceil(sectionSkills.length / 2);

    const sectionSkillsLeft = sectionSkills.slice(0, arrayHalfPoint);
    const sectionSkillsRight = sectionSkills.slice(arrayHalfPoint, sectionSkills.length);

    return (
        <div className={styles.skillSection}>
            <h3>{sectionName}</h3>

            <SkillListSection skills={sectionSkillsLeft} />
            <SkillListSection skills={sectionSkillsRight} />

        </div>
    );
};

SkillList.propTypes = {
    skills: PropTypes.array.isRequired,
    sectionName: PropTypes.string.isRequired
};


export default SkillList;
