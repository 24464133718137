import React from "react";
import { HashRouter, Route, Switch } from "react-router-dom";
import { hot } from 'react-hot-loader';

import styles from './main-styles.module.css';

import MiniMenu from './components/common/MiniMenu';
import Sidebar from './components/common/Sidebar';

import Home from './views/Home';
import Resume from './views/Resume';
import Portfolio from './views/Portfolio';
import PortfolioItemPage from './components/PortfolioItemPage';

class Main extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            miniMenuCollapsed: true
        };

        this.handleMiniMenuToggle = this.handleMiniMenuToggle.bind(this);
        this.handleNavigation = this.handleNavigation.bind(this);
    }

    handleMiniMenuToggle() {
        this.setState(prevState => ({
            miniMenuCollapsed: !prevState.miniMenuCollapsed
        }));
    }

    handleNavigation() {
        this.setState({
            miniMenuCollapsed: true
        });
    }

    render() {
        return (
            <HashRouter basename="/">
                <div className={styles.splitlayout}>

                    <MiniMenu miniMenuToggleHandler={() => this.handleMiniMenuToggle} isMiniMenuCollapsed={this.state.miniMenuCollapsed} />
                    <Sidebar navigationHandler={() => this.handleNavigation} isMiniMenuCollapsed={this.state.miniMenuCollapsed} />

                    <div className={styles.page + ' ' + styles.pageRight + ' ' + styles.fullHeight}>
                        <div className={styles.pagePerspective}>

                            <section className={'padding-bottom-90' + ' ' + styles.pageInner}>
                                <Switch>
                                    <Route exact path="/" component={Home} />
                                    <Route path="/resume" component={Resume} />
                                    <Route path="/portfolio/:id" component={PortfolioItemPage} />
                                    <Route path="/portfolio" component={Portfolio} />
                                    <Route component={Home} />
                                </Switch>
                            </section>

                        </div>
                    </div>

                </div>
            </HashRouter>
        );
    }
}

export default hot(module)(Main);
